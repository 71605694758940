<template>
  <b-container class="viewer">
    <b-row
      class="w-100"
      align-h="end"
    >
    <span> PATIENT APPOINTMENT  </span>   <b>  [{{ this.$route.params.id || this.selectedDoctypeReference}}]</b>
    </b-row>

    <b-row class="w-100">
      <doctype-render
        class="px-4 py-2"
        style="width: 100%"
        :fields="[]"
        :refresh="refresh"
        :doctype-input="doctype"
        :doc_ref="doctypeReference"
      />
    </b-row>
  </b-container>
</template>

<script>
import DoctypeRender from '@/views/components/formbuilder/html/doctype/DoctypeRender.vue'

export default {
  name: 'Viewer',
  components: { DoctypeRender},
  props: {
    selectedDoctype: { type: String, default: null, required: false },
  },
  data() {
    return {
      doctype: 'Patient Appointment' ,
      visible: false,
    }
  },
  computed: {
    doctypeReference() {
      return this.$route.params.id || this.selectedDoctypeReference
    },
  },
  created() { },
  methods: {
    buttonAction(action) {
      this.$emit('button-action', action)
    },
  },
}
</script>

<style scoped>
.viewer {
    overflow-y: scroll;

    /* height: calc(100vh - 80px); */
}

.render {}
</style>
