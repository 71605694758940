<template>
  <b-container
    class="w-100"
    style="padding-left:15px !important"
  >
    <b-row>
      <b-col
        v-for="section in sections"
        :key="section"
        cols="12"
        class="mb-1"
      >
        <b-card
          v-if="section.fields.length > 0 && !section.collapsible"
          :title="section.name"
        >
          <b-card-text>
            <b-row class="mt-2">
              <b-col
                v-for="field in section.fields"
                :key="field"
                :cols="getWidth(field)"
              >
                <doc-field
                  :doc-field="field"
                  :doc="doctype"
                  :read-only="readOnly"
                  @childUpdated="childUpdated"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>

        <b-card
          v-else-if="section.fields.length > 0 && section.collapsible"
          class="mb-0"
        >
          <b-card-header
            header-tag="header"
            class="p-0 header"
            role="tab"
          >
            <div
              class="head-toggle"
              @click="section.collapsed = !section.collapsed"
            >
              <b-row>
                <b-col cols="10">
                  <h5 style="margin: 0">
                    {{ section.name }}
                  </h5>
                </b-col>
                <b-col cols="2">
                  <span><i
                    class="toggler pull-right ml-4 fa"
                    :class="section.collapsed ? iconUp : iconDown"
                    aria-hidden="true"
                  />
                  </span>
                </b-col>
              </b-row>
            </div>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            v-model="section.collapsed"
            role="tabpanel"
          >
            <b-card-body>
              <b-row class="mt-0">
                <b-col
                  v-for="field in section.fields"
                  :key="field"
                  :cols="getWidth(field)"
                >
                  <doc-field
                    :doc-field="field"
                    :doc="doctype"
                    :read-only="readOnly"
                    @childUpdated="childUpdated"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-button
        v-if="!readOnly"
        variant="primary"
        @click="create()"
      >
        create</b-button>
    </b-row>
  </b-container>
</template>

<script>
import { getDoctypeWithMeta } from './forms_api'
import DocField from './DocField.vue'

export default {
  name: 'DoctypeRender',
  components: { DocField },
  props: {
    doctypeInput: { type: String, default: '' },
    doc_ref: { type: String, default: null },
    fields: { type: Array, default: [] },
    refresh: { type: String, required: false },
  },
  data() {
    return {
      doctypeData: {},
      doctype: {},
      filterEmpty: false,
      sections: [],
      readOnly: false,
      iconUp: 'fa-angle-up',
      iconDown: 'fa-angle-down',
      collapsed: false,
      allowedFields: [],
    }
  },
  watch: {
    $route(to, from) {
      this.loadDoctype()
    },
    refreshed(val) {
      this.loadDoctype()
    },
    doc_ref(vale) {
      this.loadDoctype()
    },
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      this.loadDoctype()
    })
    this.loadDoctype()
  },
  created() {
    this.isReadonly = this.readOnly
  },
  methods: {
    loadDoctype() {
      //  this.show_alert("loading form..", 5);
      if (window.currentDoctype) {
        this.doctypeInput = window.currentDoctype
      }
      if (window.currentDoctypeReference) {
        this.doc_ref = window.currentDoctypeReference
      }

      if (this.doc_ref) {
        this.readOnly = true
      }
      const payload = { doctype: this.doctypeInput, name: this.doc_ref }
      getDoctypeWithMeta(payload).then(data => {
        this.doctypeData = data
        this.fields = data.meta.fields
        if (data.allowedFields && data.allowed_fields.allowed_fields) {
          this.allowedFields = data.allowed_fields.allowed_fields
        }

        this.doctype = data.data
        if (window.currentDoctype) {
          this.doctype.doctype = window.currentDoctype
        }

        this.getSections(this.fields)
      })
    },
    getSections(fields) {
      this.sections = []
      let currentSection = { fields: [], name: '' }
      const checkList = this.allowedFields
        .filter(x => x.active)
        .map(v => v.field_name)
      fields.forEach(item => {
        if (item.fieldtype !== 'Section Break') {
          if (
            item.fieldname !== 'workflow_state'
            && item.fieldname !== 'naming_series'
            && item.fieldtype !== 'Button'
            && item.fieldtype !== 'Html'
          ) {
            if (
              checkList.indexOf(item.fieldname) > -1
              || checkList.length == 0
            ) {
              currentSection.fields.push(item)
            }
          }
        } else {
          this.sections.push(currentSection)
          currentSection = { fields: [], name: '' }
          currentSection.name = this.noSnake(item.fieldname)
          currentSection.collapsed = false
          currentSection.collapsible = item.collapsible
        }
      })

      if (this.sections.length === 0) {
        currentSection = { fields: [], name: '' }
        currentSection.collapsed = false
        currentSection.collapsible = false

        fields.forEach(item => {
          if (
            item.fieldname !== 'workflow_state'
            && item.fieldname !== 'naming_series'
            && item.fieldtype !== 'Button'
            && item.fieldtype !== 'Html'
          ) {
            if (checkList.indexOf(item.fieldname) > -1) {
              currentSection.fields.push(item)
            }
          }
        })

        this.sections.push(currentSection)
      }
    },
    noSnake(stringItem) {
      if (!stringItem) {
        return ''
      }

      if (stringItem.startsWith('section_break')) {
        return null
      }

      if (stringItem.startsWith('sb_')) {
        stringItem = stringItem.replace('_sb', '')
      }

      stringItem = stringItem.replaceAll('section_break', 'section')
      const noSnakeString = stringItem.replaceAll('_', ' ')
      return noSnakeString.charAt(0).toUpperCase() + noSnakeString.slice(1)
    },

    getWidth(field) {
      if (field) {
        if (field.fieldname.startsWith('column_break')) {
          return '12'
        }
        if (field.fieldtype === 'Table') {
          return '12'
        }

        if (field.fieldtype === 'Text') {
          return '12'
        }

        if (field.fieldtype === 'Text Editor') {
          return '12'
        }
      }
      return '6'
    },
    childUpdated(val) {
      if (this.doctype[val.fieldname] !== val.val) {
        this.$set(this.doctype, val.fieldname, val.val)
      }
    },
    create() {
      createDoctype(this.doctype).then(data => {})
    },
  },
}
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.head-toggle {
  outline: 0;
}
.header {
  background: transparent;
}
.card-header {
  /* padding: .5rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03); */
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
</style>
